.p-datepicker-group {
  .p-datepicker-calendar {
    @include for(medium-resolution) {
      margin: 0px;
    }

    th {
      padding: 3px;

      @include for(medium-resolution) {
        padding: 0px;
      }

      span {
        font-size: 14px;

        @include for(medium-resolution) {
          font-size: 12px;
        }
      }
    }

    td {
      padding: 3px;

      @include for(medium-resolution) {
        padding: 0px;
      }

      span {
        font-size: 14px;

        @include for(medium-resolution) {
          font-size: 12px;
        }
      }
    }
  }

  .p-datepicker-header {
    padding: 4px;

    @include for(medium-resolution) {
      padding: 2px;
    }

    .p-link > span {
      @include for(medium-resolution) {
        font-size: 12px;
      }
    }

    .p-datepicker-title {
      .p-datepicker-month {
        border-radius: 5px;

        @include for(medium-resolution) {
          font-size: 12px;
        }
      }

      .p-datepicker-year {
        border-radius: 5px;

        @include for(medium-resolution) {
          font-size: 12px;
        }
      }
    }
  }
}
