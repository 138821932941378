@import '../../layout/variables';
@import '../../layout/sass/mixins';

.landing-veiculo-container {
	margin: 30px;
	width: 100%;
	max-width: 930px;
	margin-right: auto;
	margin-left: auto;
	background: rgb(255, 255, 255);
	border-radius: 30px;

	@include for(mobile-resolution) {
		margin: 0px !important;
		border-radius: 0px !important;
	}

	.landing-veiculo-header {
		height: 75px;
		border-bottom: 1px solid #e8e9eb;
		display: flex;
		justify-content: center;

		.header-text {
			line-height: 75px;
			font-weight: 600;
			font-size: xx-large;
			color: $heading;
			text-overflow: ellipsis;
			word-wrap: break-word;
			overflow: hidden;

			@include for(mobile-resolution) {
				margin: 0px !important;
				border-radius: 0px !important;
				font-size: 1.5rem;
			}
		}

		.logo-empresa {
			object-fit: cover;
			border-radius: 30px;
			height: calc(100% - 10px);
			margin-top: 5px;
			margin-right: 10px;
		}
	}

	.landing-veiculo-footer {
		height: 60px;
		border-top: 1px solid #e8e9eb;
		display: flex;
		justify-content: center;

		.footer-text-container {
			width: 100%;
			display: flex;
			justify-content: center;
		}

		.footer-text {
			font-weight: 500 !important;
			color: $heading;
			font-size: 1rem;
			text-decoration: underline;
			color: blue;
			margin-right: 120px;

			@include for(mobile-resolution) {
				margin: 0px !important;
				border-radius: 0px !important;
				font-size: 0.85rem;
				line-height: 60px;
			}

			&:hover {
				cursor: pointer;
			}
		}

		.logo-sances {
			margin-left: 30px;
			margin-top: 10px;
			height: calc(100% - 20px);

			@include for(mobile-resolution) {
				margin-top: 17.5px;
				margin-left: 5px;
				height: calc(100% - 35px);
			}
		}
	}

	.images-background {
		.img-container {
			display: flex;
			justify-content: center;
			min-width: 720px;
			min-height: 400px;
			align-items: center;

			@include for(mobile-resolution) {
				min-width: 290px;
				min-height: 300px;
			}

			.p-galleria-item-nav {
				color: $accent;
				box-shadow: none !important;

				&:hover {
					background-color: transparent;
				}
			}

			.p-galleria-item {
				min-width: 720px;
				min-height: 400px;

				@include for(mobile-resolution) {
					min-width: 290px;
					min-height: 300px;
				}

				button {
					color: black !important;
				}
			}

			.img {
				max-width: 720px;
				max-height: 400px;
				border-radius: 30px;
				vertical-align: middle !important;

				@include for(mobile-resolution) {
					max-width: 290px !important;
					max-height: 300px !important;
				}
			}
		}

		.img-row {
			padding-top: 30px;
			margin-left: 75px;
			margin-right: 75px;
			display: flex;
			height: 220px;
			justify-content: center;

			@include for(mobile-resolution) {
				margin-left: 0px !important;
				margin-right: 0px !important;
			}

			.p-carousel-prev,
			.p-carousel-next {
				box-shadow: none !important;
				color: $accent;
			}

			.small-img-container {
				display: flex;
				justify-content: center;

				&:hover {
					cursor: pointer;
				}

				.img {
					max-height: 135px;
					max-width: 180px;
					border-radius: 15px;
				}
			}
		}
	}

	.veiculo-header {
		text-align: center;
		padding: 30px;

		h4 {
			font-size: 28px;
			margin: 0px;

			@include for(mobile-resolution) {
				margin: 0px !important;
				border-radius: 0px !important;
				font-size: 1rem;
			}
		}

		h3 {
			font-size: 36px;
			margin: 0px;
			font-weight: 500;

			@include for(mobile-resolution) {
				margin: 0px !important;
				border-radius: 0px !important;
				font-size: 1.3rem;
			}
		}
	}

	.veiculo-info {
		padding: 30px;
		padding-left: 45px;
		padding-right: 45px;
		margin-left: 75px;
		margin-right: 75px;
		border-top: 1px solid black;
		display: flex;

		@include for(mobile-resolution) {
			margin: 0px !important;
			padding-left: 20px !important;
			padding-right: 20px !important;
		}
	}

	.veiculo-info-column {
		width: 50%;
		padding-left: 20px;
		padding-right: 10px;
	}

	.veiculo-info-row {
		font-size: 14px;

		span {
			color: #8a9099;
		}
	}

	.veiculo-items {
		border-top: 1px solid black;
		padding-top: 25px;
		text-align: center;
		padding-left: 45px;
		padding-right: 45px;
		margin-left: 75px;
		margin-right: 75px;

		@include for(mobile-resolution) {
			margin: 0px !important;
			padding-left: 20px !important;
			padding-right: 20px !important;
		}
	}

	.veiculo-items-header {
		color: #3f434a;
		font-weight: 500;
		font-size: 17px;
		margin-bottom: 30px;
	}

	.veiculo-items-list {
		display: flex;
		flex-wrap: wrap;
	}

	.veiculo-items-row {
		width: 50%;
		padding-right: 15px;
		padding-left: 20px;
		text-align: left;
		color: #8a9099;
	}

	.share-button-container {
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		display: flex;
		justify-content: center;
		padding: 45px;
		padding-bottom: 20px !important;

		button {
			display: flex;
			align-items: center;
			background-color: #e8e9eb;
			border: 1px solid #e8e9eb;
			padding: 10px;
			padding-right: 14px;
			padding-left: 14px;
			font-family: 'Poppins', sans-serif;
			color: #3f434a;
			border-radius: 12px;
			cursor: pointer;
			transition: 0.18s ease;

			&:hover {
				background-color: #dadbdd;
				border: 1px solid #dadbdd;
			}

			i {
				margin-right: 10px;
			}
		}
	}
}

.imagemGaleria img {
	max-width: 720px;
	max-height: 400px;
	border-radius: 30px;
	vertical-align: middle !important;	

	@include for(mobile-resolution) {
		max-width: 290px !important;
		max-height: 300px !important;
	}
}

.p-galleria-item-prev-icon{
	z-index: 1;
}