.p-accordion.normal.unique {
  .p-accordion-tab .p-accordion-header {
    .p-accordion-header-link {
      border-radius: 25px 25px 25px 25px !important;
    }

    &:not(.p-disabled).p-highlight {
      .p-accordion-header-link {
        border-radius: 25px 25px 0px 0px !important;
      }
    }
  }

  .p-accordion-content {
    border-radius: 0px 0px 25px 25px !important;
  }
}

.p-accordion.normal.many .p-accordion-tab:last-child {
  .p-accordion-header {
    .p-accordion-header-link {
      border-bottom: 0px !important;
      border-bottom-left-radius: 25px !important;
      border-bottom-right-radius: 25px !important;
    }

    &:not(.p-disabled).p-highlight .p-accordion-header-link {
      border-radius: 0px !important;
    }
  }

  .p-accordion-content {
    border-radius: 0px 0px 25px 25px !important;
  }
}

.p-accordion.normal {
  .p-accordion-tab {
    &:first-child {
      .p-accordion-header .p-accordion-header-link {
        border-radius: 25px 25px 0px 0px;
        border-top: 0px !important;
      }
    }

    .p-accordion-header {
      &:not(.p-disabled).p-highlight .p-accordion-header-link {
        border-bottom: 1px solid $border !important;
      }

      .pi.pi-angle-double-down {
        color: $bodyLight !important;
        font-size: 15px !important;
        position: relative;
        padding-right: 9px !important;
      }

      .pi.pi-angle-double-up {
        color: $bodyLight !important;
        font-size: 15px !important;
        position: relative;
        padding-right: 9px !important;
      }

      .p-accordion-header-link {
        border: 0px !important;
        background-color: white !important;
        padding: 15px;
        padding-left: 45px;
        padding-right: 50px;
        box-shadow: none !important;
        border-bottom: 1px solid $border !important;
        height: 62px !important;

        @include for(medium-resolution) {
          padding: 0px;
          padding-left: 25px;
          padding-right: 30px;
          height: 52px !important;
        }

        .p-accordion-toggle-icon {
          visibility: hidden;
          margin-right: 0px;
        }
      }
    }
  }

  .p-accordion-header-text {
    color: $heading;
    font-size: 20px;
    font-weight: 500 !important;
    flex: 1;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @include for(medium-resolution) {
      font-size: 17px !important;
    }
  }

  .p-accordion-content {
    border: 0px;
    padding-top: 40px;
    padding-left: 60px;
    padding-right: 60px;
    border-bottom: 1px solid $border !important;

    @include for(medium-resolution) {
      padding-top: 20px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  @include for(mobile-resolution) {
    .p-accordion-header {
      padding-left: 0px !important;
      padding-right: 0px !important;

      .p-accordion-header-link {
        padding-right: 20px !important;
        padding-left: 10px !important;
      }
    }

    .p-accordion-content {
      padding-top: 20px;
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}

.p-accordion.report {
  .p-disabled {
    opacity: 1 !important;
  }

  .p-accordion-content {
    padding: 0px;
  }

  .p-accordion-tab .p-accordion-header .p-accordion-header-link {
    box-shadow: none !important;
    border: 2px solid #dee2e6 !important;
    border-radius: 0px;
    padding: 7px;

    .p-accordion-toggle-icon {
      display: none;
      margin-right: 0px;
    }

    span {
      font-size: 14px;

      @include for(medium-resolution) {
        font-size: 13px;
      }
    }
  }
}