@import '../../../layout/variables';
@import '../../../layout/sass/mixins';


.labelPag {
  &.isLoja {
    color: $darkOrange;
    background-color: #fff7f2;
  }

  &.isCliente {
    background-color: #ecf6ef;
    color: $darkGreen;
  }

  text-align: center;
  display: inline-block;
  font-size: 1rem;
  font-weight: normal;
  padding: 6px;
  padding-right: 12px;
  padding-left: 12px;
  border-radius: 7px;
  margin-top: 8px;
  	
  @include for(medium-resolution) {
    font-size: 0.8rem !important;
  }
}