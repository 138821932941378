@import "../../../layout/variables";

.tabMenu-header-cellphone {
  background-color: #ffffff !important;
  font-weight: 500 !important;
  color: $bodyDark !important;
  padding: 15px 0px 15px 20px;
  border-radius: 25px 25px 0px 0px !important;
  margin: 0px !important;
  font-size: 1.5rem !important;
}
