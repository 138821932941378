.datalist {
    @media (min-width: 640px) {
        text-align: center;
    }

    th,
    td {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

p-datatable-scrollable-body {
    max-height: 50vh;
}

.p-datatable .p-datatable-tbody > tr > td {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

.p-datatable-frozen-view > .p-datatable-scrollable-body{
    padding-bottom: 30px !important;
}

.p-datatable-thead > tr > th {
    padding: 0.7rem !important;
}

.p-datatable .p-sortable-disabled {
    padding: 1rem 1rem !important;
}

.p-datatable-tbody > tr {
    height: 30px !important;
    min-height: 30px !important;
    max-height: 30px !important;
  }

@media screen and (max-width: 768px) {
    .p-datatable.without-header-checkbox .p-datatable-tbody {
        .p-highlight {
            background-color: #d6ddff !important;
            color: $accent !important;
        }

        & > tr:not(.p-highlight):hover {
            background-color: transparent !important;
        }
    }

    .p-datatable.p-datatable-responsive .p-datatable-tbody > .p-datatable-row {
        &:first-child {
            border-top: 0px;
        }

        border-top: 1px solid rgb(202, 202, 202);
    }

    .p-datatable-row > td:first-child {
        margin-top: 12px;
    }

    .p-datatable-row > td:last-child {
        margin-bottom: 12px;
    }

    .p-datatable.p-datatable-responsive .p-datatable-thead > tr > th,
    .p-datatable.p-datatable-responsive .p-datatable-tfoot > tr > td {
        display: none !important;
    }
    .p-datatable.p-datatable-responsive .p-datatable-tbody > tr:not(.p-row-expand) > td {
        display: block;
        float: left;
        height: auto;
    }
    .p-datatable.p-datatable-responsive .p-datatable-tbody > .p-row-expand{
        background: #000;
        height: 20px;
    }

    .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
        text-align: left !important;
        width: 100% !important;
        clear: left;
        border: 0 none;
        // display: flex;

        &.p-selectable-row{
              display: block;
        float: left;
        background: #000;
        }
        &.p-row-expanded{
            background: #000;
        }

        &.p-selection-column {
            height: 0px !important;
            padding: 0px !important;

            .p-checkbox {
                visibility: hidden;
            }
        }
    }

    .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
        padding: 0.4rem;
        min-width: 35%;
        max-width: 35%;
        display: block;
        margin: -0.4em 1em -0.4em -0.4rem;
        font-weight: bold;
        float: left;
        text-align: left;
    }

    .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:last-child {
        border-bottom: 1px solid var(--surface-d);
    }
}

.p-datatable.report-datatable {
    .p-datatable-thead > tr > th {
        padding: 5px;
        border-bottom: 1.5px solid #e8e9eb;

        .p-column-title {
            color: #3f4146;
            font-weight: 600;
            font-size: 12.5px;
        }
    }

    .p-datatable-tbody > tr > td {
        padding: 5px !important;
        font-size: 12.5px;
        border: none !important;
    }
}

@include for(mobile-resolution) {
    .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:last-child {
        border-bottom: 1px solid var(--surface-d) !important;
    }
}
