.ErrorPage {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: 100vh;
    justify-content: center;
    align-items: center;
    color: #c9d1d9;

    h1 {
        font-size: 6em;

        @include for(medium-resolution) {
            font-size: 5em;
        }
    }

    p {
        margin-top: 0px;
        margin-bottom: 1em;
        font-size: 3em;

        @include for(medium-resolution) {
            font-size: 2em;
        }
    }

    a {
        cursor: pointer;
    }
}