.pagamento-modal.p-dialog {
  .p-dialog-header .p-dialog-title {
    font-size: 1.5rem;

    @include for(mobile-resolution) {
      font-size: 1.3rem !important;
      margin-right: 5px;
    }
  }

  .p-dialog-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-left: 0px !important;

    @include for(mobile-resolution) {
      display: flex;
      flex-direction: column;
      padding-left: 17px !important;
    }
  }
}
