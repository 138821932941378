@import "../../../layout/sass/mixins";

.input-tooltip-container {
  position: relative !important;
}

.input-tooltip-icon {
  &:hover {
    cursor: pointer;
  }

  position: relative !important;

  margin-left: 4px;

  font-size: 16.5px;

  color: #304ffd;

  @include for(medium-resolution) {
    font-size: 14.5px;
    padding-bottom: 0px;
  }
}

.input-tooltip {
  position: absolute !important;
}
