@import "../../../layout/sass/mixins";

.footer-totalizadorColumn {
  width: 33.3%;
}

.totalizadores-container {
  display: flex;
  height: fit-content !important;
}

.footer-totalizador-label {
  padding-left: 20px;
}

.footer-totalizador {
  display: flex;
  justify-content: space-between;
}
