@import "../../../layout/variables";
@import "../../../layout/animations";
@import "../../../layout/sass/mixins";

.dropdown-container {
  width: fit-content !important;
}

.dropdown {
  position: absolute;
  margin-top: 50px;
  width: 130px;
  background-color: $bg;
  border-radius: 16px;
  padding: 0.5rem;
  box-shadow: 0 0 0.4em $bodyLight;
  overflow: hidden;
  transform-origin: top center;
  z-index: 99999;
  animation: growDown 150ms ease-in-out forwards;

  @include for(medium-resolution) {
    margin-top: 45px;
  }
}

.dropdown-button {
  height: fit-content;
  width: 130px;
  box-shadow: none !important;

  @include for(mobile-resolution) {
    border-radius: 32px !important;
  }
}

.dropdown-item {
  padding: 10px;
  &:hover {
    cursor: pointer;
  }
}
