.layout-sidebar {
  &::-webkit-scrollbar {
    width: 0px !important;
  }

  position: fixed;
  width: 270px;
  height: 100%;
  z-index: 999;
  overflow-y: auto;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  @include transition(left $transitionDuration);
  border-right: 1px solid #E8E9EB;

  .layout-logo {
    text-align: center;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;

    object {
      width: 210px;

      @include for(medium-resolution) {
        width: 195px;
      }
    }

    .logo-title {
      font-weight: 500;
      font-size: 18px;
    }
  }

  .menuitem-badge {
    display: inline-block;
    margin-left: 6px;
    font-size: 10px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    color: $menuitemBadgeColor !important;
    background-color: $topbarItemBadgeBgColor;
    @include border-radius(40%);
  }
}