@import '../../layout/variables';
@import '../../layout/animations';

.link-tooltip.p-tooltip .p-tooltip-arrow {
    border-top-color: $bodyDark;
}
.link-tooltip.p-tooltip .p-tooltip-text {
    background-color: $bodyDark;
    transform-origin: bottom center;
    animation: growDown 30ms ease-in-out forwards;
}