.row-peca-cancelada {
  color: #8a8a8a !important;
  font-style: italic !important;
}

.label-orcamento-valores, .label-orcamento-total {
  font-size: 14px;
  font-weight: 600;
  margin: 20px;
}

.text-red{
  color: #FD7972
}

.border{
  border-bottom: 1px solid rgba(128, 128, 128, 0.355);
  height: 1px;
  width: 100%;
  margin-bottom: 20px;
}