@import "../../../../layout/variables";
@import "../../../../layout/sass/mixins";

.pagar-consignados {
  &.button {
    height: min-content;
  }

  &.veiculos-conjunto {
    font-size: 17.5px;

    @include for(mobile-resolution) {
      font-size: 16.5px;
    }

    @include for(medium-resolution) {
      font-size: 15.5px;
    }
  }

  &.venda-link {
    font-weight: normal;
    font-size: 15.5px;
    padding: 6px;
    height: min-content;

    &:hover {
      cursor: pointer;
    }

    @include for(mobile-resolution) {
      font-size: 14.5px;
    }

    @include for(medium-resolution) {
      font-size: 13.5px;
    }
  }

  &.row-expansion {
    padding: 25px 60px !important;

    @include for(mobile-resolution) {
      padding: 15px 25px !important;
    }
  }
}
