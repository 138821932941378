
@import "../../../../layout/sass/mixins";
.aprovacao-negociacao {
  &.row-expansion {
    padding: 25px 60px !important;
  }
  &.button {
  
    width: 175px;
  }

  
}