@import "../../../../src/layout/variables";
@import "../../../../src/layout/sass/mixins";

.landing-pix-container {
  margin: 30px;
  width: 100%;
  max-width: 930px;
  margin-right: auto;
  margin-left: auto;
  background: rgb(255, 255, 255);
  border-radius: 30px;

  @include for(mobile-resolution) {
    margin: 0px !important;
    border-radius: 0px !important;
  }
  .landing-veiculo-header {
    border-bottom: 1px solid #e8e9eb;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 8px;
    padding: 5px 15px;
    .header-text {
      font-weight: 600;
      font-size: xx-large;
      color: $heading;
      text-overflow: ellipsis;

      @include for(mobile-resolution) {
        font-size: 1.5rem;
        align-items: center;
      }
    }

    .logo-empresa {
      max-width: 30%;
      max-height: 75px;
      object-fit: cover;
      height: calc(100% - 10px);
    }
  }
  .veiculo-header {
    text-align: center;
    padding: 30px;

    h4 {
      font-size: 28px;
      margin: 0px;

      @include for(mobile-resolution) {
        margin: 0px !important;
        border-radius: 0px !important;
        font-size: 1rem;
      }
    }

    h3 {
      font-size: 36px;
      margin: 0px;
      font-weight: 500;

      @include for(mobile-resolution) {
        margin: 0px !important;
        border-radius: 0px !important;
        font-size: 1.3rem;
      }
    }
  }
  .veiculo-info {
    padding: 30px;
    padding-left: 45px;
    padding-right: 45px;
    margin-left: 75px;
    margin-right: 75px;
    border-top: 1px solid black;
    display: flex;

    @include for(mobile-resolution) {
      margin: 0px !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
      flex-direction: column;
    }
  }

  .veiculo-info-column {
    width: 100%;
    padding-left: 20px;
    padding-right: 10px;
  }

  .veiculo-info-row {
    font-size: 14px;

    span {
      color: #8a9099;
    }
  }

  .landing-pix-header {
    display: flex;
    justify-content: center;

    .header-text {
      font-weight: bold;
      font-size: 25px;
      text-overflow: ellipsis;
      word-wrap: normal;
      overflow: hidden;

      @include for(mobile-resolution) {
        margin: 0px !important;
        border-radius: 0px !important;
        text-align: center;
      }
    }
  }
  .header-valtaxa {
    font-size: 13px;
    word-wrap: normal;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    @include for(mobile-resolution) {
      margin: 0px !important;
      border-radius: 0px !important;
      text-align: center;
    }
  }
  .pix-header {
    text-align: center;
    padding: 30px;
    border-bottom: 1px solid black;
    h4 {
      font-size: 28px;
      margin: 0px;

      @include for(mobile-resolution) {
        margin: 0px !important;
        border-radius: 0px !important;
        font-size: 1rem;
      }
    }

    h3 {
      font-size: 36px;
      margin: 0px;
      font-weight: 500;

      @include for(mobile-resolution) {
        margin: 0px !important;
        border-radius: 0px !important;
        font-size: 1.3rem;
      }
    }
  }
  .pix-info {
    padding: 30px;
    padding-left: 45px;
    padding-right: 45px;
    margin-left: 75px;
    margin-right: 75px;

    display: flex;

    @include for(mobile-resolution) {
      margin: 0px !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }

  .pix-info-column {
    width: 50%;
    padding-left: 20px;
    padding-right: 10px;
  }

  .pix-info-row {
    font-size: 14px;

    span {
      color: #8a9099;
    }
  }
  .landing-pix-footer {
    height: 60px;
    border-top: 1px solid #e8e9eb;
    display: flex;
    justify-content: center;

    .footer-text-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .footer-text {
      font-weight: 500 !important;
      color: $heading;
      font-size: 1rem;
      text-decoration: underline;
      color: blue;
      margin-right: 120px;

      @include for(mobile-resolution) {
        margin: 0px !important;
        border-radius: 0px !important;
        font-size: 0.85rem;
        line-height: 60px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .logo-sances {
      margin-left: 30px;
      margin-top: 10px;
      height: calc(100% - 20px);

      @include for(mobile-resolution) {
        margin-top: 17.5px;
        margin-left: 5px;
        height: calc(100% - 35px);
      }
    }
  }

  .pix-header {
    text-align: center;
    padding: 30px;

    h4 {
      font-size: 28px;
      margin: 0px;

      @include for(mobile-resolution) {
        margin: 0px !important;
        border-radius: 0px !important;
        font-size: 1rem;
      }
    }

    h3 {
      font-size: 36px;
      margin: 0px;
      font-weight: 500;

      @include for(mobile-resolution) {
        margin: 0px !important;
        border-radius: 0px !important;
        font-size: 1.3rem;
      }
    }
  }

  .pix-items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 30px;
    padding-left: 45px;
    padding-right: 45px;
    margin-left: 75px;
    margin-right: 75px;
    display: flex;

    @include for(mobile-resolution) {
      margin: 0px !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }

  .pix-info-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #e8e9eb;
    width: auto;
    text-align: center;
  }
  .pix-valor {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 10px 0px 10px;
    width: auto;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }
  .pix-dataexp {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    font-size: 12px;
    text-align: center;
  }
  .pix-situacao {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    font-size: 13px;
    text-align: center;
    font-weight: 500;

  }
  .pix-qr-img {
    width: 250px;
    height: 250px;
  }
  .pix-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 300px; //283.2px;
    justify-content: flex-start;
  }

  .pix-code-input {
    width: 100%;
    padding: 15px;
    resize: none;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    min-height: 155px;
    line-height: 1.5;
  }

  .copy-pix-btn {
    background-color: blue;
    justify-content: center;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    width: 30%;
    @include for(mobile-resolution) {
      width: 90% !important;
      border-radius: 0px !important;
    }
  }

  .share-button-container {
    text-align: center;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 10px;
    margin-left: 75px;
    margin-right: 75px;

    button {
      display: flex;
      align-items: center;
      background-color: #e8e9eb;
      border: 1px solid #e8e9eb;
      padding: 10px;
      padding-right: 14px;
      padding-left: 14px;
      font-family: "Poppins", sans-serif;
      color: #3f434a;
      border-radius: 12px;
      cursor: pointer;
      transition: 0.18s ease;

      &:hover {
        background-color: #dadbdd;
        border: 1px solid #dadbdd;
      }

      i {
        margin-right: 10px;
      }
    }
  }
  .link-whatsapp {
    padding: 10px;
  }
}
