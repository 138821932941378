/* Typography */
h1 {
    font-weight: normal;
    margin: 0;
    font-size: 24px;
}

h2 {
    font-size: 20px;
    font-weight: normal;
    margin: 0;
}

.card {
    background-color: #ffffff;
    padding: 1em;
    margin-bottom: 16px;
    @include border-radius($borderRadius);

    &.card-w-title {
        padding-bottom: 2em;
    }

    h1 {
        margin: 1em 0 0.5em 0;
        border-bottom: 1px solid #d5d5d5;
        padding: 0.1em;
        font-size: 24px;

        &:first-child {
            margin: 0 0 0.5em 0;
        }
    }
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.no-margin {
    margin: 0px !important;
}

.no-padding {
    padding: 0px !important;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.colored-label {
    text-align: center;
    display: inline-block;
    font-size: 12px;
    padding: 6px;
    border-radius: 7px;

    @media (max-width: 1500px) {
        font-size: 11.5px !important;
    }

    &.green {
        color: #20a144;
        background-color: #ecf6ef;
    }

    &.light-green {
        color: #31d8a4;
        background-color: #ecf6ef;
    }

    &.orange {
        color: #e56e19;
        background-color: #fff7f2;
    }

    &.red {
        color: #fc453b;
        background-color: #ffebea;
    }

    &.blue {
        color: #007bff;
        background-color: #e7f3ff;
    }

    &.yellow {
        color: #ff9f15;
        background-color: #fffae8;
    }

    &.fixed-length {
        min-width: 96px !important;
    }
}
