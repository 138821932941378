.Negociacao {
    .items {

        .excluir,
        .editar {

            @media screen and (min-width: 1620px) {
                padding: 0.5em;
                width: 50%;
            }

            @media screen and (min-width: 992px) and (max-width: 1620px) {
                padding: 0.5em 0em;
            }
        }
    }
}