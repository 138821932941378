.logo-empresa {
	.p-fileupload {
		.p-progressbar {
			background-color: transparent;
			border: none;
		}
		.p-fileupload-buttonbar {
			height: 0px;
			padding: 0px;
			background-color: transparent;
			border: none;
			.p-button.p-fileupload-choose {
				position: absolute;
				z-index: 1;
				margin-left: 15px;
			}
		}
		.p-fileupload-content {
			padding: 0px;
			height: 200px;
			background-color: transparent;
			border: none;
		}
	}

	.current-logo {
		height: 130px;
		width: 130px;
		border-radius: 10px;
		object-fit: cover;
		position: absolute;
		margin-top: 51px;
		margin-left: 19px;
	}
}
