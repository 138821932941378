@include for(mobile-resolution) {
  .p-toast {
    width: 90vw !important;

    &-top-rigth {
      right: 5px !important;
    }
  }

  .p-toast-message-content {
    .p-toast-message-icon {
      font-size: 25px !important;
    }

    .p-toast-summary {
      font-size: 15.5px !important;
    }

    .p-toast-detail {
      font-size: 14px !important;
    }
  }
}
