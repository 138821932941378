@import "../../../layout/variables";
@import "../../../layout/sass/mixins";

.descricao-vei-label {
  color: $bodyLight;
  margin-right: 5px;
  font-size: 15px;
}

.descricao-vei-row {
  margin-bottom: 5px;
}

.descricao-vei-tipo {
  margin-left: 5px;
}

.descricao-vei-value {
  font-size: 15px;
}

.negociacao-compra-descri {
  padding-left: 8px;
  font-size: 15px;
}

.totalizador-label {
  min-width: 100%;
  color: #3f434a;
  padding: 0.3rem;
  margin: -0.4em 1em 0em 0rem;
  font-weight: bold;
  line-height: 25px;
}

.descrição-situacao-manutencao {
  color: blue;
  font-size: 15.5px;

  @include for(mobile-resolution) {
    font-size: 13.5px;
  }
}
