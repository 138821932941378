@import "../../../../../layout/_variables";
@import "../../../../../layout/sass/mixins";

.divider {
	width: 100% !important;
	border-bottom: 2px solid #e8e9eb !important;
	display: flex !important;
	justify-content: space-between !important;
	margin-bottom: 30px !important;
}

.form-divider {
	width: 100% !important;
	border-bottom: 1px solid #e8e9eb !important;
	margin-bottom: 20px !important;
}

.neg-row {
	padding-left: 20px !important;
	padding-bottom: 6px !important;

	.neg-row-label {
		color: $bodyLight !important;
		margin-right: 0.45em !important;
		font-size: 1rem !important;

		@include for(medium-resolution) {
			font-size: 0.85rem !important;
		}
	}

	.neg-row-value {
		color: $heading !important;
		font-size: 1rem !important;

		@include for(medium-resolution) {
			font-size: 0.85rem !important;
		}
	}

	&.placa {
		padding-bottom: 0px !important;
	}

	&.first {
		padding-top: 30px !important;

		@include for(medium-resolution) {
			padding-top: 15px !important;
		}
	}
}

.neg-tipo-veiculo {
	position: relative;
	margin-left: 10px !important;
	bottom: 3px !important;
}

.neg-checkbox-container {
	display: flex;
	flex-direction: column;
	justify-content: center;

	.neg-checkbox {
		margin-top: 10px !important;
	}
}

.neg-kmatual {
	margin-top: 37px;
}

.neg-header-template {
	background-color: #ffffff !important;
	font-weight: 500 !important;
	color: $bodyDark !important;
	padding: 15px 0px 15px 20px;
	border-radius: 25px 25px 0px 0px !important;
	margin: 0px !important;
	font-size: 1.5rem !important;
}

.p-button {
	&.anterior-button {
		background-color: white !important;
		border: 1px solid #e8e9eb !important;

		.p-button-label {
			color: $bodyDark;
		}

		&:hover {
			background-color: #e8e9eb90 !important;
		}
	}

	&.neg-button {
		width: auto !important;
		@include for(mobile-resolution) {
			width: 80px !important;
		}
		.pi {
			color: black !important;
		}
	}
	&.proximo-button > .pi {
		color: white !important;
	}
}

.impressao-neg-buttons {
	display: flex !important;
	align-items: center !important;
	flex-direction: column !important;
	margin-right: 20px !important;
	margin-top: 15px !important;
}

.impressao-neg-modal {
	width: min-content !important;
}

.label-over-vehicle-cadastro.neg {
	width: calc(100% - 240px) !important;
	left: 188px !important;
}

.tooltip {
	display: inline-block;
	width: 100%;
	height: 100%;
}

.neg-documento-isCortesia {
	color: darkorange !important;
}

.neg-observacoes {
	opacity: 1 !important;
	border: none !important;
	width: 100%;

	& > div {
		text-align: justify;
	}

	&-button {
		height: 74% !important;
		border-top-right-radius: 14px !important;
		border-bottom-right-radius: 14px !important;
	}
}

.neg-pagamento {
	&.isLoja {
		color: #e56e19;
		background-color: #fff7f2;
	}
	&.isCliente {
		background-color: #ecf6ef;
		color: #20a144;
	}
	text-align: center;
	display: inline-block;
	width: 120px !important;
	font-size: 12px;
	padding: 6px;
	border-radius: 7px;
}

.neg-pagamento-valores {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 15px;
	border-radius: 20px;
	border: 1px solid $border;

	label {
		color: $heading;

		@include for(mobile-resolution) {
			font-size: 13.5px !important;
		}
	}

	.valor-total {
		font-weight: 500;
		font-size: 18px;

		@include for(mobile-resolution) {
			font-size: 13.5px !important;
		}
	}
}

.neg-pagamento-button {
	width: 230px !important;
	@include for(mobile-resolution) {
		margin-top: 20px !important;
	}
}

.neg-pagamento-footer {
	@include for(mobile-resolution) {
		display: flex;
		align-items: flex-end;
	}
}

.situacao-neg {
	color: $accent;
	font-size: larger;
	font-weight: 500;
}

.page-header.neg {
	@include for(mobile-resolution) {
		h4 {
			font-size: 24px;
			font-weight: normal;
		}
	}
}

.neg-pagamento {
	&-soma {
		color: #20a144;
		font-size: larger;
	}
	&-subtrai {
		color: red;
		font-size: larger;
	}
}

.titulo-veiculo {
	@include for(medium-resolution) {
		font-size: 17px;
	}
}
