@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", "Open Sans", "Helvetica Neue", sans-serif !important;
  font-size: $fontSize;
  color: $textColor;
  background-color: $bodyBgColor;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;

  @include for(medium-resolution) {
    font-size: 12px;
  }
}

a {
  text-decoration: none;
}

.layout-wrapper {
  padding: 0;
  min-height: 100vh;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #555;
}

.p-overflow-hidden {
  overflow: auto !important;
}