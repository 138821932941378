.table-preferences-container {
  padding: 4px;

  @include for(medium-resolution) {
    padding: 0px;
  }

  .table-preferences-label {
    @include for(medium-resolution) {
      display: flex;
      align-items: center;
    }
  }

  .table-preferences-switch {
    @include for(medium-resolution) {
      margin-top: 5px;
    }

    span {
      @include for(medium-resolution) {
        font-size: 5px;
        height: 24px;
      }

      &::before {
        @include for(medium-resolution) {
          width: 1rem;
          height: 1rem;
          left: 0.45rem;
          margin-top: -0.5rem;
        }
      }
    }
  }
}
