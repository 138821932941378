@import '../../../layout/sass/mixins';

.data-list-form {
	.p-datatable-thead {
		th {
			border-bottom: 1px solid black !important;
			padding-bottom: 5px !important;

			.p-column-title {
				color: black !important;
				font-size: 1rem !important;
			}
		}
	}
	.p-datatable-footer {
		padding-top: 30px !important;
		background-color: transparent !important;
		border-top: 1px solid black !important;
		border-bottom: none !important;
		
		@include for(mobile-resolution) {
      padding-top: 15px !important;
		}
	}
	/* Estilos para a classe .p-datatable-dragpoint-top */
	/* Estilos para telas abaixo de 769px */
	@media (max-width: 769px) {
		.p-datatable-dragpoint-top > td:not(:first-child) {
			box-shadow: inset 0 2px 0 0 transparent !important; /* ou a cor do box-shadow desejada */
		}
	}

	/* Estilos para a classe .p-datatable-dragpoint-bottom */
	/* Estilos para telas abaixo de 769px */
	@media (max-width: 769px) {
		.p-datatable-dragpoint-bottom > td:not(:last-child) {
			box-shadow: inset 0 2px 0 0 transparent !important; /* ou a cor do box-shadow desejada */
		}
	}

}
