@import "./_variables";
@import "./sass/mixins";

.page-header {
    @media (min-width: 768px) {
        h4 {
            padding-left: 15px !important;
        }
    }

    @include for(mobile-resolution) {
        display: block !important;

        h4 {
            width: 100%;
            font-size: 26px;
            margin-top: 15px !important;
            margin-bottom: 15px !important;
        }

        .page-header-buttons {
            padding-top: 10px !important;
            padding-bottom: 10px !important;

            button {
                width: 100%;
                margin: 0px !important;
                margin-bottom: 10px !important;
            }
        }
    }
}

.p-error {
    color: #dc3545 !important;
}

.info-selection {
    font-size: 1.2rem;

    @media (max-width: 1500px) {
        font-size: 1rem;
    }

    cursor: pointer;

    &.warning {
        font-size: 1.4rem !important;
    }
}

.p-component,
.p-dropdown-label {
    font-family: "Poppins", "Open Sans" !important;
}

.p-component {
    @include for(medium-resolution) {
        font-size: 13px !important;
    }
}

h4 {
    font-weight: 500;
    font-size: 28px;

    @include for(medium-resolution) {
        font-size: 22px !important;
    }
}

h6 {
    font-weight: 500;
    font-size: 18px;
    color: $heading;
    padding: 3px 0px 3px 0px;
    margin: 0;
}

.p-inputtext {
    border-radius: 13px;
    border-color: $border;
    padding: 10px;
    display: flex;
    align-items: center;

    @include for(medium-resolution) {
        padding: 6px 12px;
        font-size: 13.5px !important;
    }

    &::placeholder {
        color: #4e5257 !important;
    }
}

.p-dropdown {
    border-radius: 14px;
    border-color: $border;
    padding: 2px;

    @include for(mobile-resolution) {
        height: 47px;
    }

    @include for(medium-resolution) {
        height: 34.2px;
    }

    @include for(big-resolution) {
        height: 45.57px;
    }

    &-label.p-placeholder {
        color: #4e5257 !important;

        @include for(medium-resolution) {
            font-size: 13.5px !important;
        }
    }
}

.small-icon-button {
    width: 2rem !important;
    padding: 4px 0px !important;
}

.p-button {
    background-color: $accent;
    border-radius: 16px;
    padding: 8px;
    padding-left: 15px;
    padding-right: 15px;
    border-color: $accent !important;
    border-bottom: 2px solid #263fca !important;

    @include for(medium-resolution) {
        padding: 6px !important;
        padding-left: 12px !important;
        padding-right: 12px !important;
    }

    .p-button-label {
        color: $bg;
        font-weight: 400;
        font-size: 14px;

        @include for(medium-resolution) {
            font-size: 12.3px !important;
        }
    }

    &:hover {
        background-color: #2843e1 !important;
    }
}

.p-button-danger {
    background-color: $red !important;
    border-color: $red !important;
    border-bottom: 2px solid $darkRed !important;

    &:hover {
        background-color: #ff635a !important;
    }
}

.p-button-secondary {
    background-color: #6c757d !important;
    border-color: #6c757d !important;
    border-bottom: 2px solid #454b50 !important;

    &:hover {
        background-color: #636a70 !important;
    }
}

.calendario{
    border: none  !important;
 background-color: #304FFD;
   border-radius: 3px !important;
    padding: 8px !important; 
   padding-left: 15px !important; 
   padding-right: 15px !important; 
    border-color: transparent !important;

    .p-button-label ,.p-c{
        text-shadow: none;
        color:#64748B;
        font-weight: bolder;
    }

    &:hover{
        background-color: rgba(206, 203, 203, 0.607) !important;
    }
}

.login-button {
    background-color: #018085 !important;
    border: #018085 !important;
    border-bottom: 2px solid #018085 !important;
    height: 5vh;
    border-radius: 40px;
    gap: 10px;
    flex-shrink: 0;
    font-size: 18px !important;

    &:hover {
        background-color: #018085 !important;
    }
}

.table-header {
    background-color: #ffffff;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 25px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    border-radius: 30px 30px 0px 0px;
}


.table-footer {
    background-color: #ffffff;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0px 0px 30px 30px;
    margin-bottom: 15px;
    padding: 0px !important;

    .table-rows {
        display: flex;
        align-items: center;
        margin-left: 8px;
    }

    .table-rows-quantity {
        padding: 12px;
        color: $bodyLight;
        font-size: 15px;

        @include for(medium-resolution) {
            font-size: 13px;
        }
    }

    @include for(mobile-resolution) {
        display: block;
    }
}

.table-options-container {
    width: 100%;
    border-bottom: 2px solid $border;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    .table-options {
        display: flex;
    }

    .table-preferences {
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;

        @include for(mobile-resolution) {
            justify-content: end;
            padding: 0px;
            margin-top: 12px;

            .preferences-button {
                font-size: 22px;
            }
        }
    }

    .filter-option {
        display: flex;
        align-items: center;
        padding-top: 14px;
        padding-bottom: 12px;
        margin-right: 14px;
        cursor: pointer;
        border-bottom: 2px solid transparent;

        &:hover {
            .option-label {
                color: #63676d;
            }
        }
    }

    .option-label {
        font-size: 15px;
        color: $bodyLight;
        transition: 0.33s ease;

        @include for(medium-resolution) {
            font-size: 13px !important;
        }
    }

    .option-quantity {
        margin-left: 4px;
        background-color: $border;
        color: $bodyLight;
        font-weight: 600;
        padding: 3px;
        border-radius: 6px;
        font-size: 10px;
        padding-left: 5px;
        padding-right: 5px;

        @include for(medium-resolution) {
            font-size: 9px !important;
        }
    }

    .active {
        .option-label {
            color: $heading;
        }

        border-bottom: 2px solid $accent;
    }

    @include for(mobile-resolution) {
        display: block;
        border: none;
        margin-bottom: 23px;

        .table-options {
            display: block;
            width: 100%;
        }

        .filter-option {
            justify-content: space-between;
            margin: 0px;
        }
    }
}

.p-datatable {
    margin: 0px !important;
    padding: 0px !important;

    &.without-header-checkbox .p-datatable-thead tr>th .p-checkbox {
        visibility: hidden !important;
        height: 0px !important;
        width: 0px !important;
    }

    .p-datatable-thead {
        tr>th {
            .p-checkbox-box.p-highlight {
                border-color: #304ffd;
                background-color: #304ffd !important;
            }

            border-top: 0px;
            border-bottom: 1px solid $border;
            text-align: center;

            @include for(medium-resolution) {
                padding: 15px 0px;
            }
        }

        .p-column-title {
            font-weight: 500;
            font-size: 14px;
            color: $bodyLight;
            text-transform: uppercase;
        }

        .pi-sort-amount-down {
            color: $bodyLight !important;
        }

        .pi-sort-amount-up-alt {
            color: $bodyLight !important;
        }
    }

    .p-datatable-tbody {
        .p-highlight {
            background-color: #dddddd !important;
            color: $heading !important;

            .p-checkbox-box.p-highlight {
                border-color: #304ffd;
                background-color: #304ffd !important;
            }
        }

        .p-row-expanded>td {
            padding: 0px !important;
            margin: 0px !important;
        }

        .row-expansion {
            padding-top: 10px;
            padding-left: 2.5em;
            padding-right: 0em;
            padding-bottom: 1.25em;
            background-color: $bg !important;

            h6 {
                @include for(medium-resolution) {
                    font-size: 16.7px;
                }
            }

            .underline-link{
                text-decoration-line: underline;
                cursor: pointer;
            }

            .row-expansion-label {
                color: $bodyLight;
                margin-right: 0.45em;
                font-size: 13px;

                @include for(medium-resolution) {
                    font-size: 12.5px;
                }
            }

            .row-expansion-value {
                color: $heading;
                font-size: 13px;

                @include for(medium-resolution) {
                    font-size: 12.5px;
                }
            }

            .consignado-color {
                color: #e56e19 !important;
            }

            .row-expansion-buttons {
                @include for(mobile-resolution) {
                    display: block !important;
                    width: 100% !important;
                }
            }
            .row-expansion-buttons-info {
                @include for(mobile-resolution) {
                    display: block !important;
                    width: 20% !important;
                    height: 20% !important;
                }
            }

            .row-expansion-button {
                flex: 1;
                align-self: flex-end;

                @include for(medium-resolution) {
                    padding: 4px !important;

                    .p-button-label {
                        font-size: 11.5px !important;
                    }
                }

                @include for(mobile-resolution) {
                    width: 100% !important;
                    margin: 0px !important;
                    margin-top: 5px !important;
                    margin-bottom: 5px !important;
                    z-index: 1000 !important;
                }
            }
            .row-expansion-button-info {
                align-self: flex-end;
                width: 20% !important;

                @include for(medium-resolution) {
                    padding: 4px !important;

                    .p-button-label {
                        font-size: 11.5px !important;
                    }
                }

                @include for(mobile-resolution) {
                    width: 20% !important;
                    margin: 5px !important;
                    margin-top: 5px !important;
                    margin-bottom: 5px !important;
                    z-index: 1000 !important;
                }
            }
            .expansion-button {
                align-self: flex-end;

                @include for(medium-resolution) {
                    padding: 4px !important;

                    .p-button-label {
                        font-size: 11.5px !important;
                    }
                }

                @include for(mobile-resolution) {
                    width: 100% !important;
                    margin: 0px !important;
                    margin-top: 5px !important;
                    margin-bottom: 5px !important;
                }
            }

            @include for(mobile-resolution) {
                padding: 10px;
            }
        }

        tr {
            color: $heading;
            font-size: 15px;

            @include for(medium-resolution) {
                font-size: 11.7px !important;
            }

            td {
                border-bottom: 1px solid $border;
            }
        }
    }

    .nowrap-td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.p-menuitem-text {
    @include for(medium-resolution) {
        font-size: 13px !important;
    }
}

.layout-topbar-menu {
    .p-splitbutton-defaultbutton {
        padding: 0px !important;
        cursor: initial;

        &:hover {
            background-color: transparent !important;
        }

        span {
            font-size: 15px;
        }
    }

    .p-splitbutton-menubutton {
        padding: 0px !important;

        &:hover {
            background-color: transparent !important;
        }

        span {
            font-size: 12px;
        }
    }
}

.veiculo-disponibilidade-D {
    font-size: 12px;
    color: #20a144;
    background-color: #ecf6ef;
    padding: 6px;
    border-radius: 7px;

    @include for(medium-resolution) {
        font-size: 11px;
    }
}

.veiculo-disponibilidade-B,
.veiculo-disponibilidade-C,
.veiculo-disponibilidade-F,
.veiculo-disponibilidade-I,
.veiculo-disponibilidade-R,
.veiculo-disponibilidade-T,
.veiculo-disponibilidade-V {
    font-size: 12px;
    color: #e56e19;
    background-color: #fff7f2;
    padding: 6px;
    border-radius: 7px;

    @include for(medium-resolution) {
        font-size: 11px;
    }
}

.aquisicao, .aquisicao-1,.aquisicao-2, .aquisicao-3, .aquisicao-null {
    font-size: 12px;
    padding: 6px;
    border-radius: 7px;

    @include for(medium-resolution) {
        font-size: 11px;
    }
}
.aquisicao-1 {
    color: #e56e19;
    background-color: #fff7f2;
}
.aquisicao-2 {
    color: #20a144;
    background-color: #ecf6ef;
}
.aquisicao-null {
    color: #a12020;
    background-color: #f6ecec;
}

.aquisicao-3 {
    color: $accent;
    background-color: #eef1ff;
}

.tipo-veiculo-n,
.tipo-veiculo-u,
.veiculo-situacao-perven-1,
.veiculo-situacao-perven-0 {
    font-size: 12px;
    padding: 6px;
    border-radius: 7px;

    @include for(medium-resolution) {
        font-size: 11px;
    }
}

.tipo-veiculo-n,
.veiculo-situacao-perven-1 {
    color: #20a144;
    background-color: #ecf6ef;
}
.entrega-autorizada-1 {
    color: #b63333;
    background-color: #f52d2d2b;
    font-size: 12px;
    padding: 6px;
    border-radius: 7px;
}
.entrega-autorizada-2 {
    color: #20a144;
    background-color: #ecf6ef;
    font-size: 12px;
    padding: 6px;
    border-radius: 7px;
}

.tipo-veiculo-u,
.veiculo-situacao-perven-0 {
    color: $accent;
    background-color: #eef1ff;
}

.operacaoneg-Compra,
.operacaoneg-Venda,
.operacaoneg-Troca,
.operacaoneg-Consignação {
    font-size: 12px;
    padding: 6px;
    border-radius: 7px;

    @include for(medium-resolution) {
        font-size: 11px;
    }
}

.operacaoneg-Compra,
.operacaoneg-Consignação {
    color: #20a144;
    background-color: #ecf6ef;
}

.operacaoneg-Troca {
    color: $darkOrange;
    background-color: #f8ede6;
}

.operacaoneg-Venda {
    color: $accent;
    background-color: #eef1ff;
}

.secondary-info {
    color: $bodyLight;
}

.preferences-button {
    font-weight: bold;
    color: $bodyLight;
    font-size: 19px;
    cursor: pointer;
    transition: 0.33s ease;

    &:hover {
        color: #63676d;
    }
}

.p-paginator {
    margin-top: 0px !important;
    border-radius: 30px !important;

    .p-paginator-first,
    .p-paginator-prev,
    .p-paginator-next,
    .p-paginator-last {
        background-color: #eaedff !important;

        span {
            color: $accent !important;

            @include for(medium-resolution) {
                font-size: 14px !important;
            }
        }
    }

    .p-disabled {
        color: $bodyLight !important;
    }

    .p-paginator-element {
        border-color: transparent !important;
        border-radius: 11px !important;
        margin-left: 3px !important;
        margin-right: 3px !important;
        color: $heading !important;
    }

    .p-paginator-pages {
        .p-highlight {
            background-color: $accent !important;
            color: white !important;
        }
    }

    &-page {
        @include for(medium-resolution) {
            font-size: 14px !important;
        }
    }

    @include for(mobile-resolution) {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.p-datatable .p-datatable-tbody>tr>td {
    padding: 12px !important;
    text-align: center;

    @include for(medium-resolution) {
        padding: 12px 4px !important;
    }

    @include for(mobile-resolution) {
        padding: 5px !important;
        max-width: 100% !important;
    }
}

.p-dialog {
    max-height: 98% !important;

    border: 1px solid transparent !important;
    border-radius: 20px 20px 0px 0px !important;

    .p-dialog-header-close {
        background-color: transparent !important;
    }

    .p-dialog-header {
        .p-dialog-title {
            font-size: 1.5rem !important;

            @include for(medium-resolution) {
                font-size: 1.1rem !important;
            }
        }

        padding-left: 30px;
        border-radius: 23px 23px 0px 0px !important;
    }

    .p-dialog-content {
        padding-left: 30px;
        padding-right: 30px;
        border-radius: 0px 0px 0px 0px !important;

        @include for(medium-resolution) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .p-dialog-buttons {
        button {
            margin: 0 0.5rem 0 0;
            width: auto;
        }

        AppButton {
            margin: 0 0.5rem 0 0;
            width: auto;
        }

        margin-top: 15px !important;
        text-align: right !important;
    }

    .p-dialog-footer {
        border-top: none !important;
        padding-top: 0px !important;
        border-radius: 0px 0px 23px 23px !important;
    }
}

.p-inputswitch-slider {
    border-radius: 50px !important;

    &::before {
        border-radius: 50px !important;
    }
}

.p-inputswitch.p-inputswitch-checked {
    .p-inputswitch-slider {
        background-color: $accent;
    }

    &:not(.p-disabeld):hover .p-inputswitch-slider {
        background-color: $accent;
    }
}

.p-fieldset {
    .p-fieldset-legend {
        font-weight: 300 !important;
    }
}

.p-panel {
    .p-panel-header {
        border: 0px !important;
        background-color: white;
        padding: 15px !important;
        padding-left: 60px !important;
        padding-right: 50px !important;
        border-radius: 25px 25px 0px 0px;

        @include for(medium-resolution) {
            padding: 0px !important;
            padding-left: 35px !important;
            padding-right: 30px !important;
            height: 52px !important;
        }

        .pi {
            color: $bodyLight !important;
            font-size: 15px;
        }
    }

    .p-panel-title {
        color: $heading;
        font-size: 20px;
        font-weight: 500 !important;

        @include for(medium-resolution) {
            font-size: 17px;
        }
    }

    .p-panel-content {
        border: 0px;
        border-top: 1px solid $border;
        padding-top: 20px;
        padding-left: 30px;
        padding-right: 30px;
        border-radius: 0px 0px 25px 25px;
        padding-bottom: 20px !important;
    }

    @include for(mobile-resolution) {
        .p-panel-header {
            padding-left: 25px !important;
            padding-right: 20px !important;
        }

        .p-panel-content {
            padding-top: 20px;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

.p-scrolltop {
    border-radius: 100px;
    background-color: $accent !important;
    opacity: 0.75;

    &:hover {
        opacity: 0.9;
    }
}

.active-menuitem>a {
    background-color: $bg !important;

    i {
        color: $heading !important;
    }
}