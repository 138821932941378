@import '../../../layout/variables';

.p-treeselect {
  border-radius: 14px !important;
  border-color: $border !important;
  padding: 3px !important;

  &::placeholder {
      color: #4e5257 !important;
  }
}

.p-treenode-content {
  box-shadow: none !important;
}

.p-treenode-content.p-highlight {
  .p-tree-toggler {
      color: #6c757d !important;
  }
  .p-checkbox-box {
      border-color: $accent !important;
      background: $accent !important;
  }
  background-color: transparent !important;
  color: #212529 !important;
}